<template>
  <div class="consultingManagement">
    <ds-header title="企业列表"></ds-header>
    <div>
      <a-form class="formModel" layout="inline" :labelCol="{ style: 'width: 130px' }">
        <a-form-item label="企业/团队名称：" class="d-flex">
          <a-input style="width: 200px;" v-model.trim="search.enterprise_name" placeholder="请输入企业/团队名称" />
        </a-form-item>
        <a-form-item label="Fellow姓名/手机号：" class="d-flex">
          <a-input style="width: 200px;" v-model.trim="search.fellow_phone_name" placeholder="请输入Fellow姓名/手机号" />
        </a-form-item>
        <a-form-item label="统一社会信用代码：" class="d-flex">
          <a-input style="width: 200px;" v-model.trim="search.us_code" placeholder="请输入统一社会信用代码" />
        </a-form-item>
        <a-form-item label="入驻项目：" class="d-flex">
          <!-- <a-select style="width: 200px" placeholder="请选择入驻项目" v-model="search.project_id"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
            <a-select-option :value="item.id" v-for="item in projectList" :key="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select> -->
          <a-cascader expandTrigger="hover" style="width: 100%;" v-model="search.project_id"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectList"
            :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }" placeholder="请选择所属项目" />
        </a-form-item>
        <a-form-item label="行业分类" class="d-flex">
          <!-- <a-input style="width: 200px;" v-model.trim="search.industry_name" placeholder="请输入行业分类" /> -->
          <el-cascader style="width: 100%;" expand-trigger="hover" v-model="industry_id_list" ref="cascader"
            :collapse-tags="true" :options="industryTree"
            :props="{ multiple: true, label: 'industry_name', value: 'industry_id', children: 'child_list' }"
            size="small" clearable @change="changeIndustry">
          </el-cascader>
        </a-form-item>
        <a-form-item label="企业入驻状态：" class="d-flex">
          <a-select style="width: 200px;" placeholder="请选择企业入驻状态" v-model="search.enterprise_settle_status"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
            <a-select-option :value="item.enterprise_settle_id" v-for="item in enterpriseSettleList"
              :key="item.enterprise_settle_id">
              {{ item.enterprise_settle_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="企业类型：" class="d-flex">
          <a-select style="width: 200px;" placeholder="请选择企业类型" v-model="search.enterprise_type"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode">
            <a-select-option :value="item.enterprise_type_id" v-for="item in enterpriseTypes"
              :key="item.enterprise_type_id">
              {{ item.enterprise_type_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="d-flex">
          <a-button type="primary" style="margin-right: 15px;" @click="searchList">
            <a-icon type="search" />
            查询
          </a-button>
          <a-button class="m-10" @click="resetData"> 重置 </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div>
      <!-- <a-button type="primary" @click="add" style="margin-top: 15px">
        <a-icon type="plus" />
        添加
      </a-button> -->
      <a-button type="primary" v-if="btnShow()" @click="synchronizeEnterprises"
        style="margin-top: 15px;margin-left: 10px;">
        企业数据同步管理
      </a-button>
    </div>
    <div style="margin-top: 10px">
      <a-table :rowKey="(record, index) => record.menuId" :columns="table.columns" :data-source="table.data"
        :pagination="pagination" @change="pageChange" :scroll="{ x: 1500 }" bordered>
        <span slot="action" slot-scope="text, record">
          <a-button style="padding: 0 5px 0 0;" type="link" @click="lookStaff(text)">人员</a-button>
          <a-button style="padding: 0 5px 0 0;" type="link" @click="look(text)">查看</a-button>
          <a-button style="padding: 0 5px 0 0;" type="link" @click="edit(text)">编辑</a-button>
          <a-button style="padding: 0 5px 0 0;" type="link" @click="throwLease(text)"
            v-if="text.enterprise_settle_status === '1'">退租</a-button>
          <a-button style="padding: 0 5px 0 0;" type="link" @click="enterpriseEnterInfo(text)"
            v-if="text.is_fill_out">企业登记信息</a-button>
        </span>
      </a-table>
    </div>
    <a-modal v-model="visible" title="确认删除该企业/团队信息?" @ok="handleOk">
      删除后将马上清除该企业/团队下用户的全部权限,请谨慎操作!</a-modal>
  </div>
</template>

<script>
import store from "@/store";
import * as api from '@/api/enterprise/enterprise.js'
import { pagerules } from '@/utils/utils'
export default {
  data() {
    return {
      tipsTitle: '确认删除该企业/团队信息',
      tipsContent: '删除后将马上清除该企业/团队下用户的全部权限，请谨慎操作！',
      spinning: false,
      visible: false,
      show: false,
      search: {
        enterprise_name: '',
        fellow_phone_name: '',
        project_id: [],
        enterprise_settle_status: '',
        enterprise_type: '',
        us_code: '',
        industry_name: null,
        industry_id_list:[]
      },
      table: {
        columns: [
          {
            title: '企业/团队名称',
            dataIndex: 'enterprise_name',
            key: 'enterprise_name',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: '企业类型',
            dataIndex: 'enterprise_type_show',
            key: 'enterprise_type_show',
            width: 100,
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: '入驻项目',
            dataIndex: 'project_name',
            key: 'project_name',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: '行业分类',
            dataIndex: 'industry_name',
            key: 'industry_name',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: 'Fellow',
            dataIndex: 'fellow_phone_name',
            key: 'fellow_phone_name',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: '项目入驻状态',
            dataIndex: 'enterprise_settle_status_show',
            key: 'enterprise_settle_status_show',
            width: '200px',
            customRender: (text, index) => {
              return text ? text : '-'
            }
          },
          {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 200,
            scopedSlots: { customRender: 'action' }
          }
        ],
        data: []
      },
      pagination: {
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total, range) => {
          return `共${total}条`
        }
      },
      // 入驻项目
      projectList: [],
      // 是否查看
      isLook: false,
      // 企业入驻状态
      enterpriseSettleList: [],
      // 企业类型
      enterpriseTypes: [],
      batchDeleteView: true,//按钮权限   
      industryTree: [],
      height: '',
      industry_id_list:[]
    }
  },
  // // 进入路由添加缓存
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.$store.dispatch('cachedRoutes/updateAliveRoutes', ['list'])
  //   })
  // },
  // // 离开清楚缓存
  // beforeRouteLeave(to, from, next) {
  //   if ((to.name === 'add' && this.isLook) || to.name === 'look') {
  //     this.$store.dispatch('cachedRoutes/updateAliveRoutes', ['list'])
  //   } else {
  //     this.$store.dispatch('cachedRoutes/updateAliveRoutes', [])
  //   }
  //   next()
  // },
  mounted() { },
  methods: {
    async getindustryTree() {
      let res = await api.getindustryTree()
      res.data.forEach(item => {
        if (item.child_list.length > 0) {
          this.setSelectable(item.child_list)
          item.selectable = false
        } else {
          item.child_list = null
        }
      })
      this.industryTree = res.data
      console.log(this.industryTree, '============>');
    },
    setSelectable(arr) {
      arr.forEach(item => {
        if (item.child_list.length > 0) {
          this.setSelectable(item.child_list)
          item.selectable = false
        } else {
          item.child_list = null
        }
      })
    },
    searchList() {
      this.pagination.current = 1
      this.getEnterpriseName()
    },
    // 重置
    resetData() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.search = {
        enterprise_name: '',
        fellow_phone_name: '',
        project_id: [],
        enterprise_settle_status: '',
        enterprise_type: '',
        us_code: '',
        industry_name: null,
        industry_id_list:[]
      }
      this.industry_id_list = []
      this.getEnterpriseName()
    },
    pageChange(pageOption) {
      this.pagination.current = pageOption.current
      this.pagination.pageSize = pageOption.pageSize
      this.getEnterpriseName()
    },
    handleChange() { },
    // 查看人员
    lookStaff(data) {
      this.$router.push({
        path: '/enterprise/view',
        query: {
          id: data.id
        }
      })
    },
    // 编辑企业
    edit(data) {
      this.$router.push({
        path: '/enterprise/add',
        query: {
          id: data.id,
          editState: '1',
          title: '编辑企业'
        }
      })
    },
    look(data) {
      this.isLook = !this.isLook
      this.$router.push({
        path: '/enterprise/add',
        query: {
          id: data.id,
          editState: '2',
          title: '查看企业'
        }
      })
    },
    add() {
      this.$router.push({
        path: '/enterprise/add',
        query: {
          editState: '0',
          title: '添加企业'
        }
      })
    },
    synchronizeEnterprises() {
      this.$router.push({
        path: '/enterprise/synchronizeEnterprises',
        query: {
          editState: '0',
          title: '企业数据同步管理'
        }
      })
    },
    enterpriseEnterInfo(row) {
      this.$router.push({
        name: 'enterpriseInfoDetail',
        query: {
          id: row.id
        }
      })
    },
    remove(data) {
      const that = this
      that.deleteEnter(data.id)
    },
    handleOk() {
      this.visible = false
    },
    /** 网络请求 */
    // 获取企业列表
    async getEnterpriseName() {
      console.log('===============');

      try {
        this.spinning = true
        const result = await api.getEnterpriseNamePlanB(
          this.search,
          this.pagination.current,
          this.pagination.pageSize
        )
        this.spinning = false
        if (result.data.enterprises) {
          let tempArr = []
          let enterprises = result.data.enterprises
          enterprises.forEach(item => {
            let show_enter_link_person = ''
            if (item.enterprise_desc) {
              if (item.enterprise_desc.enter_link_person) {
                if (item.enterprise_desc.phone) {
                  show_enter_link_person = `${item.enterprise_desc.enter_link_person} ${item.enterprise_desc.phone}`
                } else {
                  show_enter_link_person =
                    item.enterprise_desc.enter_link_person
                }
              } else {
                if (item.enterprise_desc.phone) {
                  show_enter_link_person = item.enterprise_desc.phone
                } else {
                  show_enter_link_person = ''
                }
              }
            } else {
              show_enter_link_person = ''
            }
            item.enter_link_person = show_enter_link_person
            tempArr.push(item)
          })
          this.table.data = tempArr
          this.pagination.total = result.data.total
        }
        // this.table.data.map((item) => {
        //   let tempName = item.project_name.split(",");
        //   return (item.project_name = tempName);
        // });
      } catch (error) {
        this.spinning = false
      }
    },
    // 删除企业
    async deleteEnter(id) {
      try {
        this.spinning = true
        const result = await api.deleteEnter(id)
        this.spinning = false
        if (result.data === '删除完成') {
          this.pagination.current = pagerules(
            this.table.data.length,
            1,
            this.pagination.current
          )
          this.$message.success('删除成功')
          this.getEnterpriseName()
        }
      } catch (error) {
        this.spinning = false
      }
    },
    // 获取项目
    async getProject() {
      try {
        const res = await api.getShunyiAllProject()
        this.projectList = res.data

      } catch (error) {
        console.log(error, 'error============>');
      }
    },
    // 退租
    throwLease(val) {
      this.$router.push({
        path: '/enterprise/throwLease',
        query: {
          enterpriseId: val.id
        }
      })
    },
    // 入驻状态
    async getEnterpriseSettleList() {
      try {
        const res = await api.enterpriseSettleList()
        if (res.code === '200') {
          this.enterpriseSettleList = res.data
        }
      } catch (error) {
        console.log(error, '==================>');
      }
    },
    // 获取企业类型
    async getEnterpriseType() {
      try {
        const result = await api.getEnterpriseType()
        // 注意 该接口 的响应结构
        this.enterpriseTypes = result.data
      } catch (error) {
        console.log(error, '============>');
      }
    },
    btnShow() {
      const buttonPermissions = store.state.global.buttonPermissions;
      console.log(buttonPermissions.includes('synchronizeEnterprises'), '================>>>');
      return buttonPermissions.includes('synchronizeEnterprises');
    },
    changeIndustry(){
      this.search.industry_id_list = []
      console.log(   '======',   this.$refs['cascader'].getCheckedNodes());
      this.$refs['cascader'].getCheckedNodes().forEach(item=>[
        this.search.industry_id_list.push(item.data.industry_id)
      ])
    }
  },
  created() {
    this.getEnterpriseName()
    this.getProject()
    this.getEnterpriseSettleList()
    // 获取企业类型
    this.getEnterpriseType()
    this.getindustryTree()
  },
  mounted() {
    let handleResize = () => {
      this.height = window.innerHeight - 341 - 64 - 40 - 30
      let width = document.getElementsByClassName('consultingManagement')[0].clientWidth;
      if (width > 1890) {
        this.table.columns[this.table.columns.length - 1].fixed = '';
      } else {
        this.table.columns[this.table.columns.length - 1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .ant-table-thead>tr>th {
  padding: 8px 16px;
}

::v-deep .ant-table-tbody>tr>td {
  padding: 8px 16px;
}
</style>